<template>
  <div class="why">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animate__fadeInLeft"
                :begin="false"
            >
                <div class="why__title">
                  Why do
                  <span>I need</span>
                </div>
                <div class="why__text" v-if="textBlock">
                  <p v-html="textBlock['why_do_i_need'].description"></p>
                </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-md-6">

          <u-animate-container>
            <u-animate
                name="fadeIn"
                delay="0s"
                duration="1s"
                :iteration="1"
                :offset="0"
                animateClass="animate__fadeInRight"
                :begin="false"
            >
            <div class="why__list" v-if="textBlock">
              <div class="col-sm-4 why__block" v-for="(item, index) in whyList" :key="index">
                 <div class="why__item">
                    <div class="why__item-icon">
                      <inline-svg :src="require(`@/assets/images/svg/${item}`)"/>
                    </div>
                     <div class="why__item-title">
                       {{textBlock['why_do_i_need'][`item_${index + 1}`]}}
                     </div>
                  </div>
                </div>
              </div>
            </u-animate>
          </u-animate-container>
        </div>
        <div class="col-sm-12">
          <div class="d-flex justify-center">
            <button class="btn grey" @click="changeToggleRegistration(true)">Register</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {UAnimateContainer, UAnimate} from 'vue-wow'
import 'animate.css';
import {mapGetters, mapMutations} from "vuex";
export default {
  components: {
    UAnimateContainer,
    UAnimate
  },
  data(){
    return{
      whyList:['why-ic_1.svg', 'why-ic_2.svg', 'why-ic_3.svg', 'why-ic_4.svg', 'why-ic_5.svg', 'why-ic_6.svg']
    }
  },
  computed:{
    ...mapGetters({
      textBlock:'landing/textBlock',
    })
  },
  methods:{
    ...mapMutations({
      changeToggleRegistration: 'general/changeToggleRegistration',
    })
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.why{
  margin-bottom: 55px;
  position: relative;
  overflow-x: hidden;
  padding-top: 25px;
  &:before{
    content: '';
    position: absolute;
    right: -40px;
    top: 20%;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background-color: $white-grey;
    pointer-events: none;
  }
  &:after{
    content: '';
    position: absolute;
    left: 10%;
    top: 15%;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: $white-grey;
    pointer-events: none;
    z-index: -1;
  }
  &__title{
    font-family: 'Mont', Arial;
    font-size: 96px;
    font-weight: 900;
    color: $grey;
    line-height: 1.2;
    margin-bottom: 30px;
    @media screen and (max-width: 1220px){
      font-size: 66px;
    }
    @media screen and (max-width: 720px){
      font-size: 46px;
    }
    span{
      display: block;
      color: $yellow;
    }
  }
  &__text{
    font-family: 'Inter', Arial;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.2;
    color: $grey;
    margin-bottom: 50px;
    p{
      margin-bottom: 20px;
    }
  }
  &__block{
    &:first-child{
      .why__item-icon{
        &:before{
          transform: rotate(30deg);
        }
        &:after{
          transform: rotate(5deg);
        }
      }
    }
    &:nth-child(2){
      .why__item-icon{
        &:before{
          transform: rotate(-40deg);
        }
        &:after{
          transform: rotate(40deg);
        }
      }
    }
    &:nth-child(3){
      .why__item-icon{
        &:before{
          transform: rotate(155deg);
        }
        &:after{
          transform: rotate(130deg);
        }
      }
    }
    &:nth-child(4){
      .why__item-icon{
        &:before{
          transform: rotate(-135deg);
        }
        &:after{
          transform: rotate(57deg);
        }
      }
    }
    &:nth-child(5){
      .why__item-icon{
        &:before{
          transform: rotate(0deg);
        }
        &:after{
          transform: rotate(-110deg);
        }
      }
    }
    &:nth-child(6){
      .why__item-icon{
        &:before{
          transform: rotate(180deg);
        }
        &:after{
          transform: rotate(-40deg);
        }
      }
    }
  }
  &__icon-test{
  }
  &__item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  &__item-icon{
    height: 118px;
    width: 118px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 3px solid rgba(59, 64, 240, 0.1);
    margin-bottom: 30px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: -4%;
      top: -4%;
      height: 108%;
      width: 108%;
      pointer-events: none;
      background-image: url("~@/assets/images/svg/why-ellipse_1.svg");
      background-repeat: no-repeat;
      background-position: top center;
    }
    &:after{
      content: '';
      position: absolute;
      left: -20px;
      top: -20px;
      height: calc(100% + 40px);
      width: calc(100% + 40px);
      pointer-events: none;
      background-image: url("~@/assets/images/svg/why-ellipse_2.svg");
      background-repeat: no-repeat;
      background-position: top center;
    }
  }
  &__item-title{
    text-align: center;
  }
}
</style>
